import authService from '../../../../services/auth'
import { Auth } from 'aws-amplify'
export default {
    data() {
        return {
            password: {              
                old_password: null,
                new_password: null,
                confirm_password: null
            },
        }
    },
    created() {
        console.clear();
      },
    methods: {
        forceRerender () {
            this.$refs.modal.show()
        },
        async changePassword () {
            var isValid = await this.$refs.observer.validate()
            if (isValid) {
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        return Auth.changePassword(user, this.password.old_password, this.password.new_password).then(data =>{
                            //debugger
                            this.$refs.modal.hide()
                            this._showToast('Password changed!')                    
                            })
                            .catch(err =>{
                                console.clear();
                                //debugger
                            });
                    })
            }
        },
    }
}